<template lang="pug">
	.element-container(:style="style")
		template(v-if="isLoading")
			skeleton
		template(v-else)
			.element-container__title.flex.items-center(v-if="titleVisible" :class="titleType")
				.element-container__title-left
					slot(name="header-left")
				.element-container__title-right
					slot(name="header-right")
			.element-container__content.flex.items-center.justify-center
				slot(name="data")
			slot(name="pagination")

</template>

<script>
import Skeleton from '@/components/Nestle/Skeleton/Common.vue'
export default {
	name: "ContainerForData",
	components: {
		Skeleton,
	},
	props: {
		width: {
			type: String,
			default: '600px'
		},
		titleVisible: {
			type: Boolean,
			default: true,
		},
		titleType: {
			type: String,
			default: ''
		},
		isLoading: {
			type: Boolean,
			default: false,
		},
	},
	computed: {
		style(){
			return {
				width: this.width
			}
		}
	}
}
</script>

<style lang="scss" scoped>
.element-container{
	position: relative;
	border: 3px color(gray-400) solid;
	background-color: white;
	border-radius: 2px;
	&__title{
		width: 100%;
		height: 40px;
		border-radius: 3px 3px 0 0;
		background-color: color(gray-200);
		border-bottom: 1px color(gray-400) solid;
		padding-left: 10px;
		p {
			font-weight: 600;
			color: color(gray-700);
		}
		&-right{
			margin-left: auto;
			margin-right: 10px;
		}
		&.content {
			background-color: color(white);
			border-bottom: none;
		}
	}
	&__content{
		width: 100%;
		padding: 20px;
	}
}
</style>
